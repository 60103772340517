import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

let mm = gsap.matchMedia();

/* ================ HOME ================ */
mm.add("(min-width: 993px", () => {
    gsap.from(".home .gsap-opacity", { opacity: 0, duration: 2, ease: "power1.inOut" })
    gsap.from(".gsap-x1", { x: 500, duration: 2, ease: "power1.inOut" })
    gsap.to(".gsap-x2", { rotate: 60, duration: 8, repeat: -1, yoyo: true, ease: "power1.inOut" })
    gsap.from(".gsap-x2", { x: 800, duration: 2, ease: "power1.inOut" })
    gsap.from(".nav__menu", { y: -100, duration: 1, ease: "power1.inOut", })
})

mm.add("(max-width: 992px", () => {
    gsap.from(".home .gsap-opacity", { opacity: 0, duration: 2, })
    gsap.from(".home .gsap-x1", { opacity: 0, duration: 2, })
    gsap.from(".home .gsap-x2", { opacity: 0, duration: 2, })
    gsap.from(".nav__btns", { y: -100, duration: 1, })
})



gsap.from(".entenda__content-text", {
    opacity: 0, x: -100, duration: 1,
    scrollTrigger: {
        trigger: ".entenda",
        start: "top bottom-=200px",
    },
});
gsap.from(".entenda__content-swiper h2", {
    opacity: 0, x: 200, duration: 1,
    scrollTrigger: {
        trigger: ".entenda",
        start: "top bottom-=200px",
    },
});
gsap.from(".entenda__content-swiper .swiper", {
    opacity: 0, x: 100, duration: 1,
    scrollTrigger: {
        trigger: ".entenda",
        start: "top bottom-=200px",
    },
});
gsap.from(".certificacao__content h2", {
    opacity: 0, x: -100, duration: 1,
    scrollTrigger: {
        trigger: ".certificacao__content h2",
        start: "top bottom-=200px",
    },
});
gsap.from(".certificacao__content-1 p", {
    opacity: 0, x: -100, duration: 1,
    scrollTrigger: {
        trigger: ".certificacao__content-1 p",
        start: "top bottom-=200px",
    },
});
gsap.from(".certificacao__content-1 .box", {
    opacity: 0, x: -100, duration: 1,
    scrollTrigger: {
        trigger: ".certificacao__content-1 .box",
        start: "top bottom-=200px",
    },
});
gsap.from(".certificacao__content-2 p", {
    opacity: 0, x: 100, duration: 1,
    scrollTrigger: {
        trigger: ".certificacao__content-2 p",
        start: "top bottom-=200px",
    },
});
gsap.from(".certificacao__content-2 img", {
    opacity: 0, x: 100, duration: 1,
    scrollTrigger: {
        trigger: ".certificacao__content-2 img",
        start: "top bottom-=200px",
    },
});
gsap.from(".aquecimento svg", {
    opacity: 0, y: -100, duration: 1,
    scrollTrigger: {
        trigger: ".aquecimento svg",
        start: "top bottom-=200px",
    },
});
gsap.from(".aquecimento h2", {
    opacity: 0, y: 100, duration: 1,
    scrollTrigger: {
        trigger: ".aquecimento h2",
        start: "top bottom",
    },
});
gsap.from(".aquecimento p", {
    opacity: 0, y: 50, duration: 1,
    scrollTrigger: {
        trigger: ".aquecimento p",
        start: "top bottom",
    },
});
gsap.from(".estufa__swiper h2", {
    opacity: 0, x: -100, duration: 1,
    scrollTrigger: {
        trigger: ".estufa__swiper h2",
        start: "top bottom-=200px",
    },
});
gsap.from(".estufa__swiper .swiper", {
    opacity: 0, x: -100, duration: 1,
    scrollTrigger: {
        trigger: ".estufa__swiper .swiper",
        start: "top bottom-=200px",
    },
});
gsap.from(".estufa__content-text h2", {
    opacity: 0, x: 100, duration: 1,
    scrollTrigger: {
        trigger: ".estufa__content-text h2",
        start: "top bottom-=200px",
    },
});
gsap.from(".estufa__content-text img", {
    opacity: 0, x: 100, duration: 1,
    scrollTrigger: {
        trigger: ".estufa__content-text img",
        start: "top bottom-=200px",
    },
});
gsap.from(".aditivos__content-1 h2", {
    opacity: 0, y: 50, duration: 1,
    scrollTrigger: {
        trigger: ".aditivos__content-1 h2",
        start: "top bottom-=200px",
    },
});
gsap.from(".aditivos__content-1 p", {
    opacity: 0, y: 50, duration: 1,
    scrollTrigger: {
        trigger: ".aditivos__content-1 p",
        start: "top bottom-=200px",
    },
});
gsap.from(".aditivos__content-2 h2", {
    opacity: 0, y: 50, duration: 1,
    scrollTrigger: {
        trigger: ".aditivos__content-2 h2",
        start: "top bottom-=200px",
    },
});
gsap.from(".aditivos__content-2 .btn", {
    opacity: 0, y: 50, duration: 1,
    scrollTrigger: {
        trigger: ".aditivos__content-2 .btn",
        start: "top bottom-=200px",
    },
});
gsap.from(".bg-aditivos .aditivos__content-img", {
    opacity: 0, x: -100, duration: 1,
    scrollTrigger: {
        trigger: ".bg-aditivos .aditivos__content-img",
        start: "top bottom-=200px",
    },
});
gsap.from(".bg-aditivos .aditivos__swiper h2", {
    opacity: 0, x: 100, duration: 1,
    scrollTrigger: {
        trigger: ".bg-aditivos .aditivos__swiper h2",
        start: "top bottom-=200px",
    },
});
gsap.from(".bg-aditivos .aditivos__swiper .swiper", {
    opacity: 0, x: 100, duration: 1,
    scrollTrigger: {
        trigger: ".bg-aditivos .aditivos__swiper .swiper",
        start: "top bottom-=200px",
    },
});
// gsap.from(".mudanca__content", {
//     opacity: 0, y: 100, duration: 1,
//     scrollTrigger: {
//         trigger: ".mudanca__content",
//         start: "top bottom-=200px",
//     },
// });
gsap.from(".ondeencontrar__content", {
    opacity: 0, y: 100, duration: 1,
    scrollTrigger: {
        trigger: ".ondeencontrar__content",
        start: "top bottom-=200px",
    },
});
gsap.from(".faq__content", {
    opacity: 0, y: -100, duration: 1,
    scrollTrigger: {
        trigger: ".faq__content",
        start: "top bottom-=200px",
    },
});
gsap.from(".faq details", {
    opacity: 0, x: 100, duration: 1,
    scrollTrigger: {
        trigger: ".faq details",
        start: "top bottom-=200px",
    },
});
gsap.from(".footer svg", {
    opacity: 0, y: 100, duration: 1,
    scrollTrigger: {
        trigger: ".footer svg",
        start: "top bottom",
    },
});